// import the api endpoints
import memberApi from '@/api/member-api'
import memberStatusApi from '@/api/member-status-api'
import { Auth } from 'aws-amplify'
import router from '@/router'
import errorHandler from '@/helpers/error-handler'
import { configureAmplify } from '@/helpers/amplify-helper'
import { appModes } from '@/helpers/constants'

import adminMemberHandler from '@/helpers/admin-member-handler'
const url = location.host

const getAppModeFromUrl = () => {
  if (url.startsWith('operations')) {
    return appModes.operations
  } else if (url.startsWith('creators')) {
    return appModes.creators
  } else if (url.startsWith('localhost')) {
    return localStorage.getItem('localhostAppMode') || appModes.operations
  }
}

const getProductName = () => {
  const appMode = getAppModeFromUrl()
  if (appMode === appModes.operations) {
    return 'Operations Center'
  } else if (appMode === appModes.creators) {
    return 'Creator'
  }
}

const state = {
  member: null,
  rawAcl: null,
  acl: null,
  isAdmin: false,
  appMode: getAppModeFromUrl(),
  defaultRoute: '/',
  memberStatus: null,
  productName: getProductName(),
}

const actions = {
  // LOGIN_MEMBER can be used only for getting and setting token, not for getting member data etc.
  // It also clears login related stuff before setting token.
  async LOGIN_MEMBER(context, payload) {
    return new Promise((resolve, reject) => {
      // to make login request without token, otherwise service returns 401 even if cridentials are correct
      context.dispatch('LOGOUT_MEMBER').then(() => {
        // const success = (token, res) => {
        //   localStorage.setItem('token', token)
        //   resolve(res)
        // }

        const error = () => {
          console.error(
            'An error happened while getting token. Please check it.',
          )
        }

        // if (payload.loginToken) {
        //   success(payload.loginToken, true)
        //   return
        // }

        memberApi
          .login(
            payload.formData.email,
            payload.formData.password,
            payload.recaptchaToken,
          )
          .then((res) => {
            if (res?.signInUserSession?.idToken) {
              resolve(res)
              // success(res?.signInUserSession??.idToken, res)
            } else if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
              // login.vue will handle this
              resolve(res)
            } else {
              error()
            }
            resolve(res)
          })
          .catch((error) => {
            reject(error)
          })
      })
    })
  },

  /**
   *
   * @param {Object} context - auto filled by vue
   * @param {Object} payload
   * @param {Boolean} payload.refresh - re download member data from server
   * @returns {Promise}
   */
  async GET_MEMBER(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then((res) => {
          const token = res.getIdToken().getJwtToken()
          if (!token) {
            reject('TOKEN_NOT_FOUND')
            return
          }
          if (context.state.member && payload?.refresh !== true) {
            resolve(context.state.member)
            return
          }

          memberApi
            .getMember()
            .then((member) => {
              context.commit('SET_MEMBER', member)
              resolve(member)
            })
            .catch((error) => {
              reject(error)
            })
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  /**
   *
   * @param {Object} context - auto filled by vue
   * @param {Object} payload
   * @param {Boolean} payload.refresh - re download acl data from server
   * @returns {Promise}
   */
  async GET_ACL(context, payload) {
    return new Promise((resolve, reject) => {
      if (context.state.acl && payload?.refresh !== true) {
        resolve(context.state.acl)
        return
      }

      memberApi
        .getAcl()
        .then((res) => {
          context.commit('SET_RAW_ACL', res.data?.payload)
          context.commit(
            'SET_ACL',
            res.data?.payload.map((acl) => acl?.permission_value),
          )
          resolve(context.state.acl)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async REGISTER_MEMBER(context, payload) {
    return new Promise((resolve, reject) => {
      memberApi
        .register(
          payload.formData.email,
          payload.formData.password,
          payload.formData.phone,
          payload.formData.metaUserId,
        )
        .then((res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  async GET_MEMBER_STATUS(context, payload) {
    return new Promise((resolve, reject) => {
      // admin doesn't have member status unless a user is selected from dropdown in header
      let selectedMember = adminMemberHandler.getSelectedMember()
      if (!selectedMember && context.state.isAdmin) {
        context.commit('SET_MEMBER_STATUS', {})
        resolve(null)
        return
      }

      if (context.state.memberStatus && payload?.refresh !== true) {
        resolve(context.state.memberStatus)
        return
      }

      memberStatusApi
        .getMemberStatus()
        .then((res) => {
          context.commit('SET_MEMBER_STATUS', res.data?.payload)
          resolve(res.data?.payload)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },
  // change app mode between operations and creators for development
  async SET_APP_MODE_FOR_DEVELOPMENT(context, appMode) {
    localStorage.setItem('localhostAppMode', appMode)
    await context.dispatch('LOGOUT_MEMBER')
    configureAmplify()
  },
  async LOGOUT_MEMBER(context, payload) {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then((data) => {
          context.state.member = null
          context.state.acl = null
          context.state.isAdmin = null
          context.state.appMode = getAppModeFromUrl()
          context.state.defaultRoute = '/'
          context.state.memberStatus = null
          adminMemberHandler.clearSelectedMember()
          localStorage.removeItem('token')
          localStorage.removeItem('isAdmin')

          // set redirect queryparam
          // if payload.redirectAfterLogin is provided, add it as queryparam
          let queryForRedirect = null
          if (
            payload?.redirectAfterLogin &&
            router.options.history.location !== '/'
          ) {
            queryForRedirect = {
              redirectAfterLogin: router.options.history.location,
            }
          }

          // redirect user to login with queryparam if necessary
          let noRedirectPages = ['/pages/login', '/pages/register']
          if (!noRedirectPages.includes(router.currentRoute.value.fullPath)) {
            router.push({
              path: '/pages/login',
              ...(queryForRedirect && { query: queryForRedirect }),
            })
          }
          resolve(data)
        })
        .catch((error) => {
          errorHandler(error)
          console.error(error)
          reject(error)
        })
    })
  },
}

const mutations = {
  SET_MEMBER(state, payload) {
    state.member = payload

    const authorities =
      payload?.signInUserSession?.idToken?.payload?.['cognito:groups']

    // check and set isAdmin state
    if (
      // TODO this will be all upper case
      authorities?.includes('Admin_All_Access') ||
      localStorage.getItem('isAdmin') === 'true'
    ) {
      localStorage.setItem('isAdmin', true)
      state.isAdmin = true
    } else {
      state.isAdmin = false
      // don't add localStorage.removeItem('isAdmin') to here. because if user reloads the page,
      // response member may won't have admin authority and ACL even if user is admin
      // normally BE should return member data with ADMIN authority but currently fe has to handle it manually
      // cleanups will be controlled in login and logout actions.
    }

    // get user pool
    // const poolId = payload?.pool?.userPoolId
    // let poolName = null
    // if (poolId === process.env.VUE_APP_CREATORS_POOL_ID) {
    //   poolName = 'creators'
    // } else if (poolId === process.env.VUE_APP_OPERATIONS_POOL_ID) {
    //   poolName = 'operations'
    // }

    // state.appMode = poolName === 'creators' && appModes.creators

    // check and set default route for member type
    if (state.isAdmin) {
      state.defaultRoute = '/operations-center-youtube' // '/admin/dashboard'
    } else if (state.appMode === appModes.creators) {
      state.defaultRoute = '/creator-dashboard'
    } else if (state.appMode === appModes.operations) {
      state.defaultRoute = '/operations-center-youtube'
    } else {
      state.defaultRoute = '/'
    }
  },
  SET_MEMBER_STATUS(state, payload) {
    state.memberStatus = payload
  },
  SET_RAW_ACL(state, payload) {
    state.rawAcl = payload
  },
  SET_ACL(state, payload) {
    state.acl = payload
  },
  SET_DEFAULT_ROUTE(state, payload) {
    state.defaultRoute = payload.defaultRoute
  },
  TOGGLE_DEV_ACL(state, payload) {
    if (payload.show && state.acl?.includes('ROLE_DEVELOPER')) {
      // add DEV to acl if it is not already there
      if (!state.acl.includes('DEV_MODE')) {
        state.acl = [...state.acl, 'DEV_MODE']
      }
      localStorage.setItem('devAcl', true)
    } else {
      // Remove DEV from acl
      state.acl = state.acl.filter((a) => a !== 'DEV_MODE')
      localStorage.removeItem('devAcl')
    }
  },
  SET_PRODUCT_NAME(state) {
    if (state.appMode === appModes.operations) {
      state.productName = 'Operations Center'
    } else if (state.appMode === appModes.creators) {
      state.productName = 'Creator'
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
}
