export const appModes = {
  creators: 'CREATORS',
  operations: 'OPERATIONS',
}

export const PRIORITIES = [
  {
    id: 'veryLow',
    friendlyName: 'Very Low',
  },
  {
    id: 'low',
    friendlyName: 'Low',
  },
  {
    id: 'medium',
    friendlyName: 'Medium',
  },
  {
    id: 'high',
    friendlyName: 'High',
  },
  {
    id: 'veryHigh',
    friendlyName: 'Very High',
  },
  {
    id: 'top',
    friendlyName: 'Top',
  },
]
