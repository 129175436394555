// authority types:
// ADMIN
// CLIENT

// if acl is empty, only DEV acl can see the nav link in sidebar menu but guard option is flexible
// DEV acl can see every menu link in sidebar menu

// example for a public page that should be visible some specific acl and logged out users
// {
//   component: 'CNavItem',
//   acl: ['SITE_SPEED_TRACKER'],
//   name: 'Public page only visible for SITE_SPEED_TRACKER acl and logged out users',
//   to: '/lorem',
// },

// example for a public page that should be visible to everyone
// {
//   path: 'public-page',
//   name: 'Public page',
//   component: () => import('@/views/demo/PublicPage.vue'),
//   meta: {
//     requiresAuth: false,
//   },
// },

export default [
  {
    name: 'Admin',
    component: 'CNavItem',
    acl: ['NAV_ADMIN_TITLE'],
    to: '/admin',
    icon: 'cil-calculator',
    items: [
      // {
      //   name: 'Admin Dashboard',
      //   component: 'CNavItem',
      //   acl: ['NAV_ADMIN_DASHBOARD'],
      //   to: '/admin/dashboard',
      //   icon: 'cil-calculator',
      // },
      {
        name: 'Users',
        component: 'CNavItem',
        acl: ['NAV_ADMIN_USER'],
        to: '/admin/user',
        icon: 'cil-user',
      },
      {
        name: 'ACLs',
        component: 'CNavItem',
        acl: ['NAV_ADMIN_ACL'],
        to: '/admin/acl',
        icon: 'cil-lock-locked',
      },
      {
        name: 'Roles',
        component: 'CNavItem',
        acl: ['NAV_ADMIN_ROLE'],
        to: '/admin/role',
        icon: 'cil-group',
      },
      {
        name: 'Youtube Channels',
        component: 'CNavItem',
        acl: ['NAV_ADMIN_YOUTUBE_CHANNEL'],
        to: '/admin/youtube-channel',
        icon: 'cib-youtube',
      },
    ],
  },
  {
    name: 'Home',
    component: 'CNavItem',
    acl: ['NAV_OPS_YOUTUBE_REPORT'],
    to: '/operations-center-youtube',
    icon: 'cil-home',
    items: [
      {
        name: 'Youtube',
        component: 'CNavItem',
        acl: ['NAV_OPS_YOUTUBE_REPORT'],
        to: '/operations-center-youtube',
        icon: 'cib-youtube',
      },
      {
        name: 'Facebook',
        component: 'CNavItem',
        acl: ['NAV_OPS_FACEBOOK_REPORT'],
        to: '/operations-center-facebook',
        icon: 'cib-facebook',
      },
      {
        name: 'Instagram',
        component: 'CNavItem',
        acl: ['NAV_OPS_INSTAGRAM_REPORT'],
        to: '/operations-center-instagram',
        icon: 'cib-instagram',
      },
    ],
  },
  {
    name: 'Chrome Extension',
    component: 'CNavItem',
    acl: ['NAV_OPS_CHROME_EXTENSION'],
    to: '/chrome-extension-youtube',
    icon: 'cib-google',
    items: [
      {
        name: 'Youtube',
        component: 'CNavItem',
        acl: ['NAV_OPS_CHROME_EXTENSION_YOUTUBE'],
        to: '/chrome-extension-youtube',
        icon: 'cib-youtube',
      },
    ],
  },
  {
    name: 'Deals',
    component: 'CNavItem',
    acl: ['NAV_OPS_CLIENT_DEALS'],
    to: '/deals',
    materialIconName: 'handshake',
    materialIconClasses: 'material-symbols-outlined',
  },
  {
    name: 'Comparative Reports',
    component: 'CNavItem',
    acl: ['NAV_OPS_COMPARATIVE_REPORT'],
    to: '/comparative',
    icon: 'cil-grain',
  },
  // {
  //   name: 'Forecast Report',
  //   component: 'CNavItem',
  //   acl: ['NAV_CLIENT_DASHBOARD'],
  //   to: '/forecast-report',
  //   icon: 'cil-chartLine',
  // },
  {
    name: 'Demo Pages',
    component: 'CNavGroup',
    icon: 'cil-puzzle',
    items: [
      {
        name: 'Demo Pages',
        component: 'CNavGroup',
        to: '/demo',
        icon: 'cil-puzzle',
      },
      {
        name: 'Demo Page',
        component: 'CNavItem',
        acl: [],
        to: '/demo/main',
      },
      {
        name: 'Demo Form',
        component: 'CNavItem',
        acl: [],
        to: '/demo/demo-form',
      },
    ],
  },
  {
    // this nav item doesn't have any acl, so this won't be visible in sidebar
    name: 'Example Public page',
    component: 'CNavItem',
    to: '/demo/public-page',
    icon: 'cilGlobeAlt',
  },
  {
    name: 'Creator Dashboard',
    component: 'CNavItem',
    acl: ['NAV_CREATOR_DASHBOARD'],
    to: '/creator-dashboard',
    icon: 'cil-home',
  },
]
