export default [
  // {
  //   // this is just a placeholder page to use same route rerendering for admin mode
  //   path: 'empty',
  //   name: 'Empty',
  //   component: () => import('@/views/pages/Empty.vue'),
  // },
  {
    path: 'dashboard',
    name: 'Admin Dashboard',
    component: () => import('@/views/admin/AdminDashboard.vue'),
    meta: { requiresAcl: ['PAGE_ADMIN_USER'] },
  },
  {
    path: 'user',
    name: 'Manage Users',
    component: () => import('@/views/admin/user/ManageUsers.vue'),
    meta: { requiresAcl: ['PAGE_ADMIN_USER'] },
  },
  {
    path: 'acl',
    name: 'Manage ACLs',
    component: () => import('@/views/admin/acl/ManageAcls.vue'),
    meta: { requiresAcl: ['PAGE_ADMIN_ACL'] },
  },
  {
    path: 'role',
    name: 'Manage Roles',
    component: () => import('@/views/admin/role/ManageRoles.vue'),
    meta: { requiresAcl: ['PAGE_ADMIN_ROLE'] },
  },
  {
    path: 'youtube-channel',
    name: 'Manage Youtube Channels',
    component: () =>
      import('@/views/admin/youtube-channel/ManageYoutubeChannels.vue'),
    meta: { requiresAcl: ['PAGE_ADMIN_YOUTUBE_CHANNEL'] },
  },
]
